import { ISearchApplicationService } from "../Interfaces/ISearchApplicationService";
import { ContentBase, PopupMedia, Slide } from "../Models/ContentBase";
import { Recipe } from "../Models/Recipe";
import { Definition } from "../Models/Definition";
import { SearchResponse } from "../Models/SearchResponse";
import { IApplicationConfiguration } from "../Interfaces/Configuration/IApplicationConfiguration";
import { QueryInput } from "../Models/QueryInput";
import { VideoContent } from "../Models/VideoContent";
import { Slideshow } from "../Models/Slideshow";
import { Copyright } from "../Models/Copyright";
import { AppUrlParams } from "../Enums/AppUrlParams";
import { Language } from "../Enums/Language";
import { ILocalizationConfiguration } from "../Interfaces/Configuration/ILocalizationConfiguration";
import { GetEnumKeyByValue } from "../Utils/Utils";
import { SpecialCharacters } from "../Models/Configuration/SpanishLocalization";

export class SearchApplicationService implements ISearchApplicationService {
    private readonly Configuration: IApplicationConfiguration;

    constructor(config: IApplicationConfiguration) {
        this.Configuration = config;
    }
    GenerateContentMarkup(model: ContentBase, language: Language): string {
        let updateDate = this.GenerateUpdateDate(model.meta.updateDate, language);
        let content: string = '';
        if (model.meta.contentType === 'HealthTip') {
            content = model.body ? `<section>${model.body}</section>` : '';
        } else {
            model.body?.section.map((section) => {
                content += '<section>';
                if (section.sectionHead !== '') {
                    content += `<h2>${section.sectionHead}</h2>`;
                }
                section.html.map((sectionHtml) => {
                    content += sectionHtml;
                });
                if (section?.popupMedia?.length > 0) {
                    content += this.GeneratePopUpMediaMarkup(section.popupMedia, this.Configuration);
                }
                if (section?.slides?.length > 0) {
                    content += this.GenerateSlidesMarkup(section.slides, this.Configuration);
                }
                content += '</section>';
            }).join('')
        }

        return `
            <div id="${this.Configuration.Markup.ContentContainerWrapperId}">
            <h1 class="${this.Configuration.Markup.ContentTitleClass}">${model.title}</h1>
            ${this.GenerateLanguageSelectMarkup(language)}
            <p class="${this.Configuration.Markup.ContentUpdatedClass}">${updateDate}</p>
            ${content}
            </div>
            <div id="${this.Configuration.Markup.CopyrightContainerWrapperId}"></div>`;
    }

    GenerateRecipeContentMarkup(model: Recipe, language: Language): string {
        const localization: ILocalizationConfiguration = this.Configuration.Markup.Localization[GetEnumKeyByValue(Language, language)];
        let updateDate = this.GenerateUpdateDate(model.meta.updateDate, language);
        let dietitianTip: string = model.dietitianTip ? `<div class="mayo-intro mayo-dietitian-tip">${model.dietitianTip}</div>` : '';
        let ingredients: string = model.ingredients ? `
            <section>
                <h2>${localization.RecipeIngredientsHeading} <span class="mayo-serving-info">(${model.servingInfo})</span></h2>
                ${model.ingredients}
            </section>` : '';
        let directions: string = model.directions ? `
            <section>
                <h2>${localization.RecipeDirectionsHeading}</h2>
                ${model.directions}
            </section>` : '';

        let data: object = model.exchangeData;
        let nutritionHtml: string = '';
        if(data) {
                nutritionHtml += '<section>';
                nutritionHtml += `<h2>${localization.RecipeNutritionFactsHeading}</h2>`;
            Object.keys(data).forEach(key => {
                let keyClass = key.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
                    nutritionHtml += `<div class="mayo-nutrition-info mayo-${keyClass}">`;
                    nutritionHtml += `<h4>${data[key]['label']}</h4>`;
                    nutritionHtml += `<ul class="mayo-nutrition-list mayo-${keyClass}-list">`;
                Object.keys(data[key]).forEach(key1 => {
                    let label = data[key][key1].label;
                    let value = data[key][key1].value;
                        nutritionHtml += value ? `<li><span class="mayo-nutrition-label">${label}:</span> <span class="mayo-nutrition-value">${value}</span></li>` : '';
                });
                    nutritionHtml += '</ul>';
                    nutritionHtml += '</div>';
            });
                nutritionHtml += '</section>';
        }

        return `
                <div id="${this.Configuration.Markup.ContentContainerWrapperId}">
                <h1 class="${this.Configuration.Markup.ContentTitleClass}">${model.title}</h1>
                <p class="${this.Configuration.Markup.ContentUpdatedClass}">${updateDate}</p>
                ${dietitianTip}
                ${ingredients}
                ${directions}
                ${nutritionHtml}
                </div>
                <div id="${this.Configuration.Markup.CopyrightContainerWrapperId}"></div>`;
    }

    GenerateDefinitionContentMarkup(model: Definition, language: Language): string {
        let updateDate = this.GenerateUpdateDate(model.meta.updateDate, language);
        let description: string = model.description ? `<div class="mayo-intro mayo-description">${model.description}</div>` : '';
        let sectionHtml: string = '';
        model.topicBody.concept.map((concept => {
            sectionHtml += `<section>`;
            sectionHtml += `<h2>${concept.conceptName}</h2>`;
            let data: object = concept.section;
            Object.keys(data).forEach(key => {
                sectionHtml += data[key];
            });
            sectionHtml += '</section>';
        }));

        return `
                <div id="${this.Configuration.Markup.ContentContainerWrapperId}">
                <h1 class="${this.Configuration.Markup.ContentTitleClass}">${model.title}</h1>
                <p class="${this.Configuration.Markup.ContentUpdatedClass}">${updateDate}</p>
                ${description}
                ${sectionHtml}
                </div>
                <div id="${this.Configuration.Markup.CopyrightContainerWrapperId}"></div>`;
    }

    GenerateVideoContentMarkup(model: VideoContent, language: Language): string {
        const localization: ILocalizationConfiguration = this.Configuration.Markup.Localization[GetEnumKeyByValue(Language, language)];
        let updateDate = this.GenerateUpdateDate(model.meta.updateDate, language);
        let partnerId: string = model.kalturaVideo.dataAccount;
        let entryId: string = model.kalturaVideo.videoId;
        let uiconfId: number = this.Configuration.UiconfId;
        let uniqueObjId: number = this.Configuration.UniqueObjId;
        let videoContent;

        if(partnerId && entryId && uiconfId && uniqueObjId) {
            videoContent = `<iframe src="https://www.kaltura.com/p/${partnerId}/sp/${partnerId}00/embedIframeJs/uiconf_id/${uiconfId}/partner_id/${partnerId}?iframeembed=true&playerId=${uniqueObjId}&entry_id=${entryId}" allowfullscreen webkitallowfullscreen mozAllowFullScreen frameborder="0"></iframe>`;
        } else {
            videoContent = `<p class="mayo-warning">${localization.VideoUnableToLoadText}</p>`;
        }

        let abstract: string = model.abstract ? `<div class="mayo-intro mayo-abstract">${model.abstract}</div>` : '';
        let transcript: string = model.transcript ? `<section><div class="mayo-transcript"><h2>${localization.VideoTranscriptHeading}</h2>${model.transcript}</div></section>`: '';

        return `
                <div id="${this.Configuration.Markup.ContentContainerWrapperId}">
                <h1 class="${this.Configuration.Markup.ContentTitleClass}">${model.title}</h1>
                <p class="${this.Configuration.Markup.ContentUpdatedClass}">${updateDate}</p>
                ${abstract}
                <section>
                    <div id="${this.Configuration.Markup.VideoContainerWrapperId}">
                        ${videoContent}
                    </div>
                </section>
                ${transcript}
                </div>
                <div id="${this.Configuration.Markup.CopyrightContainerWrapperId}"></div>`;
    }

    GenerateSlideshowContentMarkup(model: Slideshow, language: Language): string {
        let updateDate = this.GenerateUpdateDate(model.meta.updateDate, language);
        let abstract = model.abstract ? `<div class="mayo-intro mayo-abstract">${model.abstract}</div>` : '';
        let slides = this.GenerateSlidesMarkup(model.slides.slide, this.Configuration);
        return `
                <div id="${this.Configuration.Markup.ContentContainerWrapperId}">
                <h1 class="${this.Configuration.Markup.ContentTitleClass}">${model.title}</h1>
                <p class="${this.Configuration.Markup.ContentUpdatedClass}">${updateDate}</p>
                ${abstract}
                ${slides}
                </div>
                <div id="${this.Configuration.Markup.CopyrightContainerWrapperId}"></div>`;
    }

    GenerateFormMarkup(): string {
        const currentLanguage = this.GetLanguage();
        const localization: ILocalizationConfiguration = this.Configuration.Markup.Localization[GetEnumKeyByValue(Language, currentLanguage)];
        return `
            <div id="${this.Configuration.Markup.FormWrapperId}">
                <label for="${this.Configuration.Markup.QueryInputId}">${localization.QueryLabelText}</label>
                ${this.GenerateLanguageSelectMarkup(currentLanguage)}
                <input type="text" id="${this.Configuration.Markup.QueryInputId}" name="mayo-health-query" />
                <button id="${this.Configuration.Markup.SearchButtonId}" type="submit" name="mayo-health-submit">${localization.SearchButtonText}</button>
                <button id="${this.Configuration.Markup.ResetButtonId}" type="reset" name="mayo-health-reset">${localization.ResetButtonText}</button>
            </div>
        `;
    }

    GenerateResultsMarkup(model: SearchResponse, queryModel: QueryInput): string {
        return `
            <div id="${this.Configuration.Markup.ResultsContainerWrapperId}">
                ${this.GenerateResultList(model, queryModel)}
                ${this.GeneratePaginationControls(model, queryModel)}
            </div>
            <div id="${this.Configuration.Markup.CopyrightContainerWrapperId}"></div>`;
    }

    GenerateContentTypeMarkup(contentType: string, model: SearchResponse, queryModel: QueryInput, config): string {
        const localization: ILocalizationConfiguration = this.Configuration.Markup.Localization[GetEnumKeyByValue(Language, queryModel.Language)];
        const spanishAccents: Object = new SpecialCharacters().SpanishAccents;

        if (model.searchResults === undefined || model.searchResults.length <= 0)
        return `<h3>${localization.ContentTypeNoContentFoundHeading}</h3><p>${localization.ContentTypeNoContentFoundText}</p>`;

        // Set everything in alpha order.
        let resultsList = model.searchResults.sort((a, b) => a.title.localeCompare(b.title, queryModel.Language, { ignorePunctuation: true }));

        let content: Array<object> = [];
        let uniqueFirstChars: Array<string> = [];
        let alphabet: Array<string> = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
        let numbers: string = '#';
        let titles: Object = config.ContentTitles;
        let pageTitle: string;
        let match: boolean;

        Object.keys(titles).forEach(function(key: string) {
           if (key.toLowerCase() === contentType) {
                pageTitle = titles[key];
                match = true;
           }
        });

        !match ? pageTitle = resultsList[0].contentType.match(/([A-Z]?[^A-Z]*)/g).slice(0,-1).join(' ') : '';

        resultsList.map(function (item) {
            let regex = /[\p{L}0-9]/u;
            let charArray: RegExpMatchArray = item.title.match(regex);
            let char = charArray[0];

            Object.keys(spanishAccents).forEach(function(key) {
                char = char.match(key) ? spanishAccents[key] : char;
            });

            if (!alphabet.includes(numbers)) {
                (uniqueFirstChars.indexOf(char) === -1 && alphabet.indexOf('#') === -1 && char.match('[0-9]') ? alphabet.unshift('#') : '');
            } else {
                (uniqueFirstChars.indexOf(char) === -1 && alphabet.indexOf('#') === -1 && char.match('[0-9]') ? alphabet.splice(0, 0, '#') : '');
            }
            (uniqueFirstChars.indexOf(char) === -1 && alphabet.indexOf('#') === -1 && char.match('[0-9]') ? alphabet.splice(0, 0, '#') : '');
            (uniqueFirstChars.indexOf(char) === -1) ? uniqueFirstChars.push(char) : '';
            content.push({char, item});
        });

        const groupBy = key => array =>
        array.reduce((objectsByKeyValue, obj) => {
            const value = obj[key];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
        }, {});

        const groupByChar = groupBy('char');
        const sorted = groupByChar(content);

        let sections: string = '';
        let char: string;

        Object.keys(sorted).forEach(function(key) {
            char = Number(key) ? 'num' : key;
            sections += `<div class="a-z-section a-z-section-${char.toLowerCase()}">`;
            sections += `<h3>${key}</h3>`;
            sections += `<ul>`;
                let items = sorted[key].map((item) => {
                return `<li><a href="${config.DirectoryUrl}${config.ContentDisplayUrl}?contentId=${item.item.contentId}&lang=${queryModel.Language}">${item.item.title}</a></li>`;
            }).join('');
            sections += items;
            sections += '</ul>';
            sections += '</div>';
        });

        let quickNav = alphabet.map(function (letter) {
            if (uniqueFirstChars.indexOf(letter) > -1 || letter === '#') {
                return `<li><a href="${letter}" class="a-z-nav a-z-active">${letter.toUpperCase()}</a></li>`;
            } else {
                return `<li><span class="a-z-nav a-z-inactive">${letter.toUpperCase()}</span></li>`;
            }
        }).join('');

        return `<div id="MayoHealthA-ZWrapper">
                    <h1>${pageTitle}</h1>
                    <div id="MayoHealthA-ZNavContainer">
                        <h3>${localization.ContentTypeAlphabeticalHeading}</h3>
                        ${this.GenerateLanguageSelectMarkup(queryModel.Language)}
                        <ul>
                            <li class="a-z-all-${queryModel.Language}"><a href="All" class="a-z-nav a-z-active a-z-selected a-z-all">${localization.ContentTypeAlphabeticalAllText}</a></li>
                            ${quickNav}
                        </ul>
                    </div>
                    <div id="MayoHealthA-ZContentContainer">
                        ${sections}
                    </div>
                </div>
                <div id="${this.Configuration.Markup.CopyrightContainerWrapperId}"></div>`;
    }

    private GeneratePopUpMediaMarkup(popupMedia: PopupMedia[], config) {
        let html: string ='<div class="content-media-block">';
        popupMedia.map((media) => {
            if (this.Configuration.DebugMode){
                html += '<img src="https://via.placeholder.com/630x350" alt="Placeholder image" />';
                html += '<p>Image Caption: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent a erat felis. Quisque ac massa sit amet augue luctus molestie. Aenean ac mollis purus. Aliquam. </p>';
            } else {
                html += `<img src="${config.MayoApiBaseUrl}\\content\\${media.image.id}\\en\\?code=${config.AzureHostKey}&client=${config.Client}-mayo-apikey-live" alt="${media.image.alt}" title="${media.image.title}" />`;
                html += `<p>${media.image.caption}</p>`;
            }
        });
        html += '</div>';

        return html
    }

    private GenerateSlidesMarkup(slides: Slide[], config) {
        let html  = '<section>';
            html += '<div class="mayo-slide-container">';
        slides.map((slide, index) => {
            html += `<div class="mayo-slide" data-mayo-slide="${index}">`;
            html += `<img src="${config.MayoApiBaseUrl}\\content\\${slide.uri.id}\\en\\?code=${config.AzureHostKey}&client=${config.Client}-mayo-apikey-live" alt="${slide.imageAltText}" height="${slide.height}" width="${slide.width}"/>`;
            html += slide.title ? `<h3>${slide.title}</h3>` : '';
            html += slide.caption ? `<div class="mayo-caption">${slide.caption}</div>` : '';
            html += '</div>';
        });
        html += '</div>';
        html += '</section>';
        return html;
    }

    private GeneratePaginationControls(model: SearchResponse, queryModel: QueryInput): string {

        let numberOfPages:number = Math.ceil(model.count / queryModel.ResultsPerPage);
        let startingResult:number = (queryModel.PageNumber - 1) * queryModel.ResultsPerPage + 1;
        let endResult:number = queryModel.PageNumber * queryModel.ResultsPerPage;
        let optionsHtml: string = '';
        const localization: ILocalizationConfiguration = this.Configuration.Markup.Localization[GetEnumKeyByValue(Language, queryModel.Language)];

        for(let i = 1; i <= numberOfPages; i++){
            optionsHtml = optionsHtml +  `<option value="${i}" ${i === queryModel.PageNumber ? 'selected="selected"' : ''}>${i}</option>`;
        }
        let prevPagingHtml = queryModel.PageNumber > 1 ? `<div id="${this.Configuration.Markup.PagingInformationPrevButtonWrapperId}">
                <button id="${this.Configuration.Markup.PagingPrevButtonId}" type="button">${localization.PagingPreviousButtonText}</button>
            </div>` : '';
        let nextPagingHtml = queryModel.PageNumber < numberOfPages ? `<div id="${this.Configuration.Markup.PagingInformationNextButtonWrapperId}">
                    <button id="${this.Configuration.Markup.PagingNextButtonId}" type="button">${localization.PagingNextButtonText}</button>
                </div>` : '';
        return `
            <div class="Pagination">
                ${prevPagingHtml}
                <div id="${this.Configuration.Markup.PagingInformationWrapperId}">
                    <span>
                        ${localization.PagingSelectText1} <div class="select-wrap"><select name="MayoHealthPagination" id="${this.Configuration.Markup.PaginationId}">
                            ${optionsHtml}
                            </select></div>
                            ${localization.PagingSelectText2} ${numberOfPages}${localization.PagingSelectText3} <span id="${this.Configuration.Markup.PagingStartResultId}">${startingResult}</span>-<span id="${this.Configuration.Markup.PagingEndResultId}">${endResult}</span> ${localization.PagingSelectText4} <span id="${this.Configuration.Markup.PagingTotalResultsId}">${model.count}</span>.</span>
                </div>
                ${nextPagingHtml}
            </div>
        `
    }

    private GenerateResultList(model: SearchResponse, queryModel: QueryInput) {
        const localization: ILocalizationConfiguration = this.Configuration.Markup.Localization[GetEnumKeyByValue(Language, queryModel.Language)];
        if(model.searchResults === undefined || model.searchResults?.length <= 0)
            return `<h3>${localization.ResultsNoResultsFoundHeading}</h3><p>${localization.ResultsNoResultsFoundText}</p>`;

        return model.searchResults.map((result) => {
                let updateDate = this.GenerateUpdateDate(result.updateDate, queryModel.Language);
                return `<div class="${this.Configuration.Markup.ResultWrapperClass}">
                        <h3><a href="${this.Configuration.DirectoryUrl}${this.Configuration.ContentDisplayUrl}?contentId=${result.contentId}&lang=${queryModel.Language}">${result.title}</a></h3>
                        <p class="${this.Configuration.Markup.ResultUpdatedClass}">${result.contentType} | ${updateDate}</p>
                        <p class="${this.Configuration.Markup.ResultAbstractClass}">${result.abstract[0] ?? result.title}</p>
                        </div>`}).join('');
    }

    GenerateUpdateDate(date, language: Language): string {
        const localization: ILocalizationConfiguration = this.Configuration.Markup.Localization[GetEnumKeyByValue(Language, language)];
        let dateParts: string[] = date.split('-');
        const months = localization.DatesMonths;
        if (language === Language.Spanish) {
            return `${localization.DatesUpdatedText}: ` + dateParts[2] + ' de ' + months[parseInt(dateParts[1], 10) - 1] + ' de ' + dateParts[0];
        }
        return `${localization.DatesUpdatedText}: ` + months[parseInt(dateParts[1], 10) - 1] + ' ' + dateParts[2] + ', ' + dateParts[0];
    }

    GenerateCopyrightContentMarkup(model: Copyright, language: Language): string {
        const localization: ILocalizationConfiguration = this.Configuration.Markup.Localization[GetEnumKeyByValue(Language, language)];
        let termsString: string;
        if (this.Configuration.TermsLocal) {
            termsString = model.meta.docId ? `<a href="${this.Configuration.DirectoryUrl}${this.Configuration.ContentDisplayUrl}?contentId=${model.meta.docId}&lang=${language}">${localization.TermsOfUse}</a>.` : '';
        } else {
            termsString = model.meta.originalUrl ? `<a href="${model.meta.originalUrl}" target="_blank">${localization.TermsOfUse}</a>.` : '';
        }

        let mayoAttribution: string = '';
        if (this.Configuration.MayoAttribution) {
            if (this.Configuration.MayoAttributionContent) {
                mayoAttribution = this.Configuration.MayoAttributionContent;
            } else {
                mayoAttribution = `<div class="mayo-copyright-logo"><img src="${this.Configuration.MayoLogoUrl}" alt="${model.meta.attribution.value}" style="width: 180px"/></div>`;
            }
        }

        return `${mayoAttribution}
                <div class="mayo-copyright-text"><p>${model.finePrintText} ${termsString}</p></div>`;
    }

    GetLanguage(): Language {
        // Match language url param value to language, return match or default language
        let params = new URLSearchParams(window.location.search);
        if (params.has(AppUrlParams.language)) {
            const value = decodeURI(params.get(AppUrlParams.language));
            if (Object.values(Language).includes(<Language>value)) {
                return <Language>value;
            }
        }
        return this.Configuration.DefaultLanguage;
    }

    GetAlternativeLanguage(currentLanguage: Language = this.GetLanguage()): Language {
        switch (currentLanguage) {
            case Language.English:
                return Language.Spanish;
            case Language.Spanish:
                return Language.English;
            default:
                return this.Configuration.DefaultLanguage;
        }
    }

    GenerateLanguageSelectMarkup(currentLanguage: Language): string {
        const altLanguage = this.GetAlternativeLanguage(currentLanguage);
        const altLocalization: ILocalizationConfiguration = this.Configuration.Markup.Localization[GetEnumKeyByValue(Language, altLanguage)];
        if (this.Configuration.LanguageSelect) {
            let html = `<div class="${this.Configuration.Markup.LanguageSelectGroupClass}">`;
            html += altLocalization.LanguageSelectBeforeText ? altLocalization.LanguageSelectBeforeText + ' ' : '';
            html += `<button id="${this.Configuration.Markup.LanguageSelectId}" type="button" class="${this.Configuration.Markup.LanguageSelectClass}" lang="${altLanguage}">${altLocalization.LanguageSelectText}</button>`;
            html += altLocalization.LanguageSelectAfterText ? ' ' + altLocalization.LanguageSelectAfterText : '';
            html += '</div>';
            return html;
        }
        return '';
    }
}

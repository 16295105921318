export class ContentBase {
    meta: Meta;
    body?: Body;
    title: string;
    abstract?: string;
    benefitSummary?: string;
    relatedLinksBox?: any;
    encoding: string;
    language: string;
    noNamespaceSchemaLocation: string;
}
export class Meta {
    attribution: Attribution;
    contentType: string;
    copyrightOwner?: CopyrightOwner;
    docId?: string;
    originalUrl: string;
    updateDate: string;
    publicationDate: string;
    controlWords?: ControlWords;
    objectId?: string;
    mayoVersion: string | number;
    metaKeywords?: any;
    hasFlash?: string;
    source?: string;
    termsOfUse: TermsOfUse;
    standardCodes?: StandardCodes;
    typeOfDefinition?: string;
    pocId?: string;
    topicId?: string;
}
export class Attribution {
    url: string;
    value: string;
}
export class CopyrightOwner {
    docId: string;
    value: string;
}
export class ControlWords {
    category?: (CategoryEntity)[] | null;
}
export class CategoryEntity {
    keyword: Keyword;
    title: string;
    mcvId: string;
}
export class Keyword {
    keyword?: Keyword1;
    title: string;
    mcvId: string;
}
export class Keyword1 {
    title: string;
    mcvId: string;
    keyword?: Keyword2 | null;
}
export class Keyword2 {
    title: string;
    mcvId: string;
}
export class TermsOfUse {
    docId: string;
}
export class StandardCodes {
    codeSet?: (CodeSetEntity)[] | null;
}
export class CodeSetEntity {
    code?: (CodeEntity)[] | null;
    value: string;
}
export class CodeEntity {
    code?: (CodeEntity1)[] | null;
    value: string;
    label: string;
}
export class CodeEntity1 {
    value: string;
    label: string;
}

export class Body {
    section: Section[];
}

export class Uri {
    id: string;
    value: string;
}

export class Slide {
    caption: string;
    title: string;
    imageAltText: string;
    height: string;
    width: string;
    uri: Uri;
}

export class Section {
    sectionHead: string;
    html: string[];
    popupMedia?: PopupMedia[];
    slides?: Slide[];
}

export class PopupMedia {
    thumbnail: Image;
    image: Image;
    html: string;
}

export class Image {
    id: string;
    alt: string;
    height: string;
    uri: string;
    width: string;
    caption: string;
    title: string;
}

import {
    ILocalizationConfiguration,
    ILocalizationSpecialCharacters
} from "../../Interfaces/Configuration/ILocalizationConfiguration";
import { Language } from "../../Enums/Language";

export class SpanishLocalization implements ILocalizationConfiguration {
    Language: Language = Language.Spanish;
    LanguageSelectText: string = 'En español';
    LanguageSelectBeforeText: string = '';
    LanguageSelectAfterText: string = '';
    QueryLabelText: string = 'Buscar información de salud de Mayo Clinic';
    SearchButtonText: string = 'Buscar';
    ResetButtonText: string = 'Reiniciar';
    PagingNextButtonText: string = "Siguiente página";
    PagingPreviousButtonText: string = "Pagina anterior";
    PagingSelectText1: string = 'Página';
    PagingSelectText2: string = 'de';
    PagingSelectText3: string = ', mostrando resultados';
    PagingSelectText4: string = 'de';
    RecipeIngredientsHeading: string = 'Ingredientes';
    RecipeDirectionsHeading: string = 'Direcciones';
    RecipeNutritionFactsHeading: string = 'Información nutricional';
    VideoUnableToLoadText: string = 'No se pudo cargar el video';
    VideoTranscriptHeading: string = 'Transcripción';
    ResultsNoResultsFoundHeading: string = 'No se encontraron resultados de búsqueda';
    ResultsNoResultsFoundText: string = 'Intente buscar utilizando palabras clave o temas alternativos';
    ContentTypeNoContentFoundHeading: string = 'No se encontró contenido';
    ContentTypeNoContentFoundText: string = 'Intente buscar utilizando palabras clave o temas alternativos';
    ContentTypeAlphabeticalHeading: string = 'Alfabético';
    ContentTypeAlphabeticalAllText: string = 'todos';
    DatesMonths: string[] = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
    DatesUpdatedText: string = 'Actualizado';
    TermsOfUse: string = 'Condiciones de uso';
}

export class SpecialCharacters implements ILocalizationSpecialCharacters {
    SpanishAccents: Object = { 'Á': 'A', 'É': 'E', 'Í': 'I',  'Ó': 'O', 'Ú': 'U', 'Ñ': 'N' };
}

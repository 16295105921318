import { IMarkupConfiguration } from "../../Interfaces/Configuration/IMarkupConfiguration";
import { EnglishLocalization } from "./EnglishLocalization";
import { SpanishLocalization } from "./SpanishLocalization";

export class MarkupConfiguration implements IMarkupConfiguration{
    FormContainerId:string = 'MayoHealthSearchForm';
    FormWrapperId:string = 'MayoHealthSearchWrapper';
    ContentContainerId: string = 'MayoHealthSearchContent';
    ContentContainerWrapperId: string = 'MayoHealthSearchContentWrapper';
    ContentContainerTypeId: string = 'MayoHealthSearchContentType';
    ContentContainerTypeWrapperId: string = 'MayoHealthContentTypeWrapper';
    CopyrightContainerWrapperId: string = 'MayoHealthCopyrightWrapper';
    ResultsContainerId: string = 'MayoHealthSearchResults';
    ResultsContainerWrapperId: string = 'MayoHealthSearchResultsWrapper';
    QueryLabelText: string = 'Search Mayo Clinic Health Information';
    SearchButtonId: string = 'MayoHealthSearchButton';
    ResetButtonId: string = 'MayoHealthResetButton';
    QueryInputId: string = 'MayoHealthQueryInput';
    ResultWrapperClass: string = 'mayo-search-result';
    ResultUpdatedClass: string = 'mayo-search-result-type-and-date';
    ResultAbstractClass: string = 'mayo-search-result-abstract';
    ContentTitleClass: string = 'mayo-content-title';
    ContentUpdatedClass: string = 'mayo-content-updated-date';
    PaginationId: string = 'MayoHealthPaginationInput';
    PagingNextButtonId: string = 'MayoPagingNextButton';
    PagingInformationWrapperId: string = 'MayoPagingInfoWrapper';
    HideClass: string = 'hide';
    ShowClass: string = 'show';
    PagingEndResultId: string = "PagingEndResult";
    PagingStartResultId: string = "PagingStartResult";
    PagingTotalResultsId: string = "PagingTotalResults";
    PagingInformationNextButtonWrapperId: string = "MayoPagingNextButtonWrapper";
    PagingInformationPrevButtonWrapperId: string = "MayoPagingPreviousButtonWrapper";
    PagingPrevButtonId: string = "MayoPagingPreviousButton";
    VideoContainerWrapperId: string = 'MayoHealthVideoContentWrapper';
    LanguageSelectId: string = 'MayoHealthLanguageSelect';
    LanguageSelectClass: string = 'mayo-language-select';
    LanguageSelectGroupClass: string = 'mayo-language-select-group';
    Localization = {
        English: new EnglishLocalization(),
        Spanish: new SpanishLocalization()
    }
}
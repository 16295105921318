import { Configuration } from "./Models/Configuration/Configuration";
import { SearchApplicationService } from "./Services/SearchApplicationService";
import { MayoHealthLibraryService } from "./Services/MayoHealthLibraryService";
import { SearchResponse } from "./Models/SearchResponse";
import { AppUrlParams } from "./Enums/AppUrlParams";
import { Language } from "./Enums/Language";
import { ContentBase } from "./Models/ContentBase";
import { Recipe } from "./Models/Recipe";
import { Definition } from "./Models/Definition";
import { QueryInput } from "./Models/QueryInput";
import { IMayoHealthLibraryService } from "./Interfaces/IMayoHealthLibraryService";
import { ISearchApplicationService } from "./Interfaces/ISearchApplicationService";
import { StaticMayoHealthLibraryService } from "./Services/StaticMayoHealthLibraryService";
import { Video } from "./Models/Video";
import { Slideshow } from "./Models/Slideshow";
import { Copyright } from "./Models/Copyright";
import { GetEnumKeyByValue } from "./Utils/Utils";
import merge from "lodash/merge";

export class MayoHealthLibrary {
    private readonly Configuration: Configuration;
    private SearchApplicationService: ISearchApplicationService;
    private MayoHealthLibraryService: IMayoHealthLibraryService;
    private ResultsHaveBeenRendered: Boolean = false;

    constructor(config: Configuration, searchService: ISearchApplicationService, mayoHealthService: IMayoHealthLibraryService) {
        config.Client = config.Client.toLowerCase();
        this.Configuration = config;
        this.SearchApplicationService = searchService;
        this.MayoHealthLibraryService = mayoHealthService;
    }

    public async RenderForm() {
        let formContainer: HTMLElement = document.getElementById(this.Configuration.Markup.FormContainerId);
        formContainer.innerHTML = this.SearchApplicationService.GenerateFormMarkup();
        let formButton: HTMLElement = document.getElementById(this.Configuration.Markup.SearchButtonId);
        let queryInput: HTMLElement = document.getElementById(this.Configuration.Markup.QueryInputId);
        let languageSelect: HTMLElement = document.getElementById(this.Configuration.Markup.LanguageSelectId);
        formButton.addEventListener('click', (event) => {
            event.preventDefault();
            const url = new URL(this.Configuration.ResultsPageUrl, window.location.href);
            url.searchParams.set(AppUrlParams.query, (<HTMLInputElement>queryInput).value);
            url.searchParams.set(AppUrlParams.language, this.SearchApplicationService.GetLanguage());
            window.location.href = url.toString();
        });
        if (languageSelect) {
            languageSelect.addEventListener('click', (event) => {
                event.preventDefault();
                const altLang = this.SearchApplicationService.GetAlternativeLanguage();
                MayoHealthLibrary.setUrlParam(AppUrlParams.language, altLang);
                this.RenderForm();
                if (this.ResultsHaveBeenRendered) {
                    this.RenderQueryResults(); // Rerender results
                }
            });
        }
    }

    private static setUrlParam(param: string, value: string) {
        // Modify search parameter value in window state and URL
        const url = new URL(window.location.toString());
        url.searchParams.set(param, value);
        window.history.replaceState({}, '', url.toString());
    }

    public async RenderQueryResults() {
        let params = new URLSearchParams(window.location.search);
        let query: string = params.has(AppUrlParams.query) ? encodeURI(params.get(AppUrlParams.query)) : '';
        let pageNumber: number = params.has(AppUrlParams.page) ? parseInt(encodeURI(params.get(AppUrlParams.page))) : 1;
        let resultsPerPage: number = params.has(AppUrlParams.perPage) ? parseInt(encodeURI(params.get(AppUrlParams.perPage))) : 12;
        let language = this.SearchApplicationService.GetLanguage();
        this.ResultsHaveBeenRendered = true;
        return await this.RenderQueryResultsInternal(query, pageNumber, resultsPerPage, language);
    };

    private async RenderQueryResultsInternal(query: string, pageNumber: number = 1, resultsPerPage: number = 12, language: Language) {
        let queryModel = new QueryInput(query, pageNumber, resultsPerPage, language);
        let resultsContainer: HTMLElement = document.getElementById(this.Configuration.Markup.ResultsContainerId);
        let resultsModel: SearchResponse = await this.MayoHealthLibraryService.GetSearchByPage(queryModel);

        resultsContainer.classList.add(this.Configuration.Markup.HideClass);
        resultsContainer.classList.remove(this.Configuration.Markup.ShowClass);
        resultsContainer.innerHTML = this.SearchApplicationService.GenerateResultsMarkup(resultsModel, queryModel);
        resultsContainer.classList.add(this.Configuration.Markup.ShowClass);
        resultsContainer.classList.remove(this.Configuration.Markup.HideClass);

        let paginationInput: HTMLElement = document.getElementById(this.Configuration.Markup.PaginationId);
        paginationInput.addEventListener('change', (event) => {
            this.RenderQueryResultsInternal(query, parseInt((<HTMLInputElement>event.target).value), resultsPerPage, language);
        });

        let pagingNextButton: HTMLElement = document.getElementById(this.Configuration.Markup.PagingNextButtonId);
        if (pagingNextButton) {
            pagingNextButton.addEventListener('click', () => {
                this.RenderQueryResultsInternal(query, pageNumber + 1, resultsPerPage, language);
            });
        }

        let pagingPreviousButton: HTMLElement = document.getElementById(this.Configuration.Markup.PagingPrevButtonId);
        if (pagingPreviousButton) {
            pagingPreviousButton.addEventListener('click', () => {
                this.RenderQueryResultsInternal(query, pageNumber - 1, resultsPerPage, language);
            });
        }
    }

    public async RenderContent() {
        let params = new URLSearchParams(window.location.search);
        let contentId: string = params.has(AppUrlParams.contentId) ? encodeURI(params.get(AppUrlParams.contentId)) : '';
        let language: Language = params.has(AppUrlParams.language) ? Language[GetEnumKeyByValue(Language, encodeURI(params.get(AppUrlParams.language)))] : this.Configuration.DefaultLanguage;
        let contentType: string = contentId.substr(0, 3);
        if (contentType === 'RCP') {
            return await this.RenderContentRecipe(contentId, language);
        } else if (contentType === 'ABT') {
            return await this.RenderContentDefinition(contentId, language);
        } else if (contentType === 'VID') {
            return await this.RenderContentVideo(contentId, language);
        } else if (contentType === 'SLS') {
            return await this.RenderContentSlideshow(contentId, language);
        } else {
            return await this.RenderContentInternal(contentId, language);
        }
    }

    private async RenderContentInternal(contentId: string, language: Language = this.Configuration.DefaultLanguage) {
        let contentContainer: HTMLElement = document.getElementById(this.Configuration.Markup.ContentContainerId);
        let contentModel: ContentBase = await this.MayoHealthLibraryService.GetContent(contentId, language);
        contentContainer.innerHTML = this.SearchApplicationService.GenerateContentMarkup(contentModel, language);
        let languageSelect: HTMLElement = document.getElementById(this.Configuration.Markup.LanguageSelectId);
        if (languageSelect) {
            languageSelect.addEventListener('click', (event) => {
                event.preventDefault();
                const altLang = this.SearchApplicationService.GetAlternativeLanguage();
                MayoHealthLibrary.setUrlParam(AppUrlParams.language, altLang);
                this.RenderContent();
            });
        }
        await this.RenderMayoBranding(language);
    }

    private async RenderContentRecipe(contentId: string, language: Language = this.Configuration.DefaultLanguage) {
        let contentContainer: HTMLElement = document.getElementById(this.Configuration.Markup.ContentContainerId);
        let contentModel: Recipe = await this.MayoHealthLibraryService.GetRecipeContent(contentId, language);
        contentContainer.innerHTML = this.SearchApplicationService.GenerateRecipeContentMarkup(contentModel, language);
        await this.RenderMayoBranding(language);
    }

    private async RenderContentDefinition(contentId: string, language: Language = this.Configuration.DefaultLanguage) {
        let contentContainer: HTMLElement = document.getElementById(this.Configuration.Markup.ContentContainerId);
        let contentModel: Definition = await this.MayoHealthLibraryService.GetDefinitionContent(contentId, language);
        contentContainer.innerHTML = this.SearchApplicationService.GenerateDefinitionContentMarkup(contentModel, language);
        await this.RenderMayoBranding(language);
    }

    private async RenderContentVideo(contentId: string, language: Language = this.Configuration.DefaultLanguage) {
        let contentContainer: HTMLElement = document.getElementById(this.Configuration.Markup.ContentContainerId);
        let contentModel: Video = await this.MayoHealthLibraryService.GetVideoContent(contentId, language);
        contentContainer.innerHTML = this.SearchApplicationService.GenerateVideoContentMarkup(contentModel, language);
        await this.RenderMayoBranding(language);
    }

    private async RenderContentSlideshow(contentId: string, language: Language = this.Configuration.DefaultLanguage) {
        let contentContainer: HTMLElement = document.getElementById(this.Configuration.Markup.ContentContainerId);
        let contentModel: Slideshow = await this.MayoHealthLibraryService.GetSlideshowContent(contentId, language);
        contentContainer.innerHTML = this.SearchApplicationService.GenerateSlideshowContentMarkup(contentModel, language, this.Configuration);
        await this.RenderMayoBranding(language);
    }

    public async RenderContentType() {
        let params = new URLSearchParams(window.location.search);
        let contentType: string = params.has(AppUrlParams.contentType) ? encodeURI(params.get(AppUrlParams.contentType)) : '';
        let language: Language = params.has(AppUrlParams.language) ? Language[GetEnumKeyByValue(Language, encodeURI(params.get(AppUrlParams.language)))] : this.Configuration.DefaultLanguage;
        return await this.RenderContentTypeInternal(contentType, language);
    }

    private async RenderContentTypeInternal(contentType: string, language: Language = this.Configuration.DefaultLanguage) {
        let resultsContainer: HTMLElement = document.getElementById(this.Configuration.Markup.ContentContainerTypeId);
        let query: string = `contentType:(${contentType})`;
        let pageNumber: number = 1;
        let resultsPerPage: number = 1000;
        let queryModel = new QueryInput(query, pageNumber, resultsPerPage, language);
        let resultsModel: SearchResponse = await this.MayoHealthLibraryService.GetSearchByPage(queryModel);
        resultsContainer.innerHTML = this.SearchApplicationService.GenerateContentTypeMarkup(contentType, resultsModel, queryModel, this.Configuration);
        let languageSelect: HTMLElement = document.getElementById(this.Configuration.Markup.LanguageSelectId);

        if (languageSelect) {
            languageSelect.addEventListener('click', (event) => {
                event.preventDefault();
                const altLang = this.SearchApplicationService.GetAlternativeLanguage(language);
                MayoHealthLibrary.setUrlParam(AppUrlParams.language, altLang);
                this.RenderContentTypeInternal(contentType, altLang);
            });
        }

        await this.RenderMayoBranding(language);

        let azQuickNav: HTMLCollection = resultsContainer.getElementsByClassName('a-z-active');
        let azSection: HTMLCollection = resultsContainer.getElementsByClassName('a-z-section');
        let azAllString: string = this.Configuration.Markup.Localization[GetEnumKeyByValue(Language, queryModel.Language)].ContentTypeAlphabeticalAllText.toLowerCase();
        let char: string;

        for (let i = 0; i < azQuickNav.length; i++) {
            azQuickNav[i].addEventListener('click', (event) => {
                event.preventDefault();
                for (let k = 0; k < azQuickNav.length; k++) {
                    k != i ? azQuickNav[k].classList.remove('a-z-selected') : '';
                }
                azQuickNav[i].classList.add('a-z-selected');
                let letter = azQuickNav[i].innerHTML.toLowerCase();
                if (letter.match('#')) {
                    char = 'num';
                } else {
                    char = letter;
                }
                for (let j = 0; j < azSection.length; j++) {
                    if (azSection[j].classList.contains(`a-z-section-${char}`)) {
                        azSection[j].classList.remove('Hide');
                    } else {
                        azSection[j].classList.add('Hide');
                    }
                    letter === azAllString ? azSection[j].classList.remove('Hide') : '';
                }
            });
        }
    }

    private async RenderMayoBranding(language: Language) {
        let contentContainer: HTMLElement = document.getElementById(this.Configuration.Markup.CopyrightContainerWrapperId);
        let contentModel: Copyright = await this.MayoHealthLibraryService.GetCopyrightContent(language);
        contentContainer.innerHTML = this.SearchApplicationService.GenerateCopyrightContentMarkup(contentModel, language);
    }

    GetEnumKeyByValue(myEnum, enumValue) {
        return GetEnumKeyByValue(myEnum, enumValue);
    }
}

export function Initialize(userConfig: Configuration) {
    let config = new Configuration();
    merge(config, userConfig);
    let searchService = new SearchApplicationService(config);
    let libraryService = config.DebugMode ? new StaticMayoHealthLibraryService() : new MayoHealthLibraryService(config)
    return new MayoHealthLibrary(config, searchService, libraryService);
}

import { ILocalizationConfiguration } from '../../Interfaces/Configuration/ILocalizationConfiguration';
import { Language } from '../../Enums/Language';

export class EnglishLocalization implements ILocalizationConfiguration {
    Language: Language = Language.English;
    LanguageSelectText: string = 'English';
    LanguageSelectBeforeText: string = '';
    LanguageSelectAfterText: string = '';
    QueryLabelText: string = 'Search Mayo Clinic Health Information';
    SearchButtonText: string = 'Search';
    ResetButtonText: string = 'Reset';
    PagingNextButtonText: string = 'Next Page';
    PagingPreviousButtonText: string = 'Previous Page';
    PagingSelectText1: string = 'Page';
    PagingSelectText2: string = 'of';
    PagingSelectText3: string = ', showing results';
    PagingSelectText4: string = 'of';
    RecipeIngredientsHeading: string = 'Ingredients';
    RecipeDirectionsHeading: string = 'Directions';
    RecipeNutritionFactsHeading: string = 'Nutrition Facts';
    VideoUnableToLoadText: string = 'Unable to load video.';
    VideoTranscriptHeading: string = 'Transcript';
    ResultsNoResultsFoundHeading: string = 'No Search Results Found';
    ResultsNoResultsFoundText: string = 'Please try searching by using alternative keywords or topics.';
    ContentTypeNoContentFoundHeading: string = 'No Content Found';
    ContentTypeNoContentFoundText: string = 'Please try searching by using alternative keywords or topics.';
    ContentTypeAlphabeticalHeading: string = 'Alphabetical';
    ContentTypeAlphabeticalAllText: string = 'ALL';
    DatesMonths: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    DatesUpdatedText: string = 'Updated';
    TermsOfUse: string = 'Terms of Use';
}
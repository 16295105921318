import { Language } from "../Enums/Language";

export class QueryInput {
    ResultsPerPage: number;
    PageNumber: number;
    Query: string;
    Language: Language;

    constructor(query: string, pageNumber: number = 1, resultsPerPage: number = 12, lang: Language) {
        this.Query = query;
        this.PageNumber = pageNumber;
        this.ResultsPerPage = resultsPerPage;
        this.Language = lang;
    }
}
import axios from 'axios';
import {IMayoHealthLibraryService} from "../Interfaces/IMayoHealthLibraryService";
import {SearchResponse} from "../Models/SearchResponse";
import {ContentBase} from '../Models/ContentBase';
import {Recipe} from "../Models/Recipe";
import {Definition} from "../Models/Definition";
import {Video} from "../Models/Video";
import {VideoStream} from "../Models/VideoStream";
import {Language} from "../Enums/Language";
import {Meta} from "../Enums/Meta";
import {IMayoHealthConfiguration} from "../Interfaces/Configuration/IMayoHealthConfiguration";
import {QueryInput} from "../Models/QueryInput";
import {VideoContent} from "../Models/VideoContent";
import {Slideshow} from "../Models/Slideshow";
import {Copyright} from "../Models/Copyright";
import {Terms} from "../Models/TermsOfUse";


export class MayoHealthLibraryService implements IMayoHealthLibraryService {
    private Configuration: IMayoHealthConfiguration;
    private readonly Client: string;

    constructor(config: IMayoHealthConfiguration) {
      this.Configuration = config;
    }

    async GetSearchByPage(queryModel: QueryInput): Promise<SearchResponse> {
        let searchResponse: SearchResponse = undefined;
        let skip = queryModel.PageNumber - 1;
        await axios.get<SearchResponse>(`${this.Configuration.MayoApiBaseUrl}content?code=${this.Configuration.AzureHostKey}&client=${this.Configuration.Client}-mayo-apikey-live&q=${queryModel.Query}&lang=${queryModel.Language}&top=${queryModel.ResultsPerPage}&skip=${skip}`)
        .then(response =>{
            searchResponse = response.data;
        })
        .catch(error =>{
            console.error('Failed getting search results from MayoHealth API:', error.response.data.Message)
        });
        return searchResponse;
    }

    async GetContent(contentId: string, lang: Language): Promise<ContentBase>{
        let contentResponse: ContentBase = undefined;
        await axios.get<ContentBase>(`${this.Configuration.MayoApiBaseUrl}content\\${contentId}\\${lang}\\?code=${this.Configuration.AzureHostKey}&client=${this.Configuration.Client}-mayo-apikey-live`)
            .then(response => {
                contentResponse = response.data;
            })
            .catch(error => {
                console.error('Failed getting search results from MayoHealth API:', error.response.data.Message)
            });
        return contentResponse;
    }

    async GetRecipeContent(contentId: string, lang: Language): Promise<Recipe>{
        let contentResponse: Recipe = undefined;
        await axios.get<Recipe>(`${this.Configuration.MayoApiBaseUrl}content\\${contentId}\\${lang}\\?code=${this.Configuration.AzureHostKey}&client=${this.Configuration.Client}-mayo-apikey-live`)
            .then(response =>{
                contentResponse = response.data;
            })
            .catch(error =>{
                console.error('Failed getting search results from MayoHealth API:', error.response.data.Message)
            });
        return contentResponse;
    }

    async GetDefinitionContent(contentId: string, lang: Language): Promise<Definition>{
        let contentResponse: Definition = undefined;
        await axios.get<Definition>(`${this.Configuration.MayoApiBaseUrl}content\\${contentId}\\${lang}\\?code=${this.Configuration.AzureHostKey}&client=${this.Configuration.Client}-mayo-apikey-live`)
            .then(response =>{
                contentResponse = response.data;
            })
            .catch(error =>{
                console.error('Failed getting search results from MayoHealth API:', error.response.data.Message)
            });
        return contentResponse;
    }

    async GetVideoContent(contentId: string, lang: Language): Promise<Video>{
        let videoResponse: Video = undefined;
        let streamResponse: VideoStream = undefined;
        let contentResponse: VideoContent = undefined;
        await axios.get<Video>(`${this.Configuration.MayoApiBaseUrl}content\\${contentId}\\${lang}\\?code=${this.Configuration.AzureHostKey}&client=${this.Configuration.Client}-mayo-apikey-live`)
        .then(response => {
            videoResponse = response.data;
            contentId = videoResponse.stream.videoFile.id;
            return axios.get<VideoStream>(`${this.Configuration.MayoApiBaseUrl}content\\${contentId}\\${lang}\\?code=${this.Configuration.AzureHostKey}&client=${this.Configuration.Client}-mayo-apikey-live`)
        .then(response => {
            streamResponse = response.data;
            })
        })
        .catch(error =>{
            console.error('Failed getting video content from MayoHealth API:', error.response.data.Message)
        });
        contentResponse = { ...videoResponse, ...streamResponse }
        return contentResponse;
    }

    async GetSlideshowContent(contentId: string, lang: Language): Promise<Slideshow> {
        let contentResponse = undefined;
        await axios.get<Slideshow>(`${this.Configuration.MayoApiBaseUrl}content\\${contentId}\\${lang}\\?code=${this.Configuration.AzureHostKey}&client=${this.Configuration.Client}-mayo-apikey-live`)
            .then(response =>{
                contentResponse = response.data;
            })
            .catch(error =>{
                console.error('Failed getting slideshow content from MayoHealth API:', error.response.data.Message)
            });
        return contentResponse;
    }

    async GetCopyrightContent(lang: Language): Promise<Copyright> {
        let contentResponse = undefined;
        let copyrightResponse = undefined;
        let termsOfUseResponse = undefined;
        await axios.get<Copyright>(`${this.Configuration.MayoApiBaseUrl}content\\${this.Configuration.Copyright}\\${lang}\\?code=${this.Configuration.AzureHostKey}&client=${this.Configuration.Client}-mayo-apikey-live`)
           .then(response => {
              copyrightResponse = response.data;
              return axios.get<Terms>(`${this.Configuration.MayoApiBaseUrl}content\\${this.Configuration.Terms}\\${lang}\\?code=${this.Configuration.AzureHostKey}&client=${this.Configuration.Client}-mayo-apikey-live`)
           })
           .then(response => {
                termsOfUseResponse = response.data;
            })
           .catch(error => {
               console.error('Failed getting copyright content from MayoHealth API:', error.response.data.Message)
           });
        contentResponse = { ...copyrightResponse, ...termsOfUseResponse };
        return contentResponse;
    }

    GetMetaValues(metaType: Meta) {
        throw("Method Not Implemented");
    }
}

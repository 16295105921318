import {Language} from "../../Enums/Language";
import {IMayoHealthConfiguration} from "../../Interfaces/Configuration/IMayoHealthConfiguration";
import {IApplicationConfiguration} from "../../Interfaces/Configuration/IApplicationConfiguration";
import {IMarkupConfiguration} from "../../Interfaces/Configuration/IMarkupConfiguration";
import {MarkupConfiguration} from "./MarkupConfiguration";

export class Configuration implements IMayoHealthConfiguration, IApplicationConfiguration {
    Markup: IMarkupConfiguration = new MarkupConfiguration();
    DefaultLanguage: Language = Language.English;
    LanguageSelect: boolean = false;
    DirectoryUrl: string = (typeof window !== 'undefined') ? window.location.pathname.indexOf('/health-info/') > -1 ? '/health-info' : '' : '';
    BaseUrl: string = '/health-information/';
    ResultsPageUrl: string = `${this.BaseUrl}search/`;
    ContentDisplayUrl: string = `${this.BaseUrl}content/`;
    Client: string;
    ContentTitles: object = {};
    MayoApiBaseUrl: string = 'https://geomayoapi.azurewebsites.net/api/';
    DebugMode: boolean = false;
    AzureHostKey: string = 'zgfa5TdIttwUHQnE/YUd1L9buCl8qhJqmQVw70aFQx2Wvtqw8S23Cg==';
    MayoContentUrl: string = 'https://cdapi.mayoclinic.org/api/v2/content';
    MayoLogoUrl: string = '/node_modules/mayo-health-library/dist/images/MayoClinic_Logo_ContentFrom.jpg';
    MayoAttribution: boolean = false;
    MayoAttributionContent: string;
    Copyright: string = 'copyright';
    Terms: string = 'termsofuse';
    TermsLocal: boolean = true;
    // For video streaming.
    KalturaId: number = 42047101; // Constant, not returned in responses (PlayerId, UiconfId, UniqueObjId all the same).
    PlayerId: number = this.KalturaId;
    UiconfId: number = this.KalturaId;
    UniqueObjId: number = this.KalturaId;
}
